<template xmlns="http://www.w3.org/1999/html">
  <div v-if="!config.isMobile">
    <div class="container-fluid p-0" >
      <div class="auth-bg-video-1 ">
        <video id="bgvid" poster="@/assets/images/pages/login/across-benlea-to-mount.jpg" playsinline="" autoplay="" muted=""
               loop="">
          <source src="@/assets/images/pages/login/auth-bg-2.mp4" type="video/mp4">
        </video>
        <div class="glassmorphism" >
        </div>
      </div>
      <div class="home-page-content">
        <div style ="padding:25px"></div>
        <HomeHeader
            :config = "config"
        ></HomeHeader>
        <div v-if="!config.isNavMenuHidden">
          <ul class="navbar-nav nav ml-auto product-nav"  >
            <div style="margin-right: 150px "></div>
            <a class ="product-nav-text-font" v-bind:style= "[config.isOption_3 ? {'color': config.activeColor} : {'color': config.disabledColor}]"
               @click="active_option_3()">
              促销活动
            </a>
            <div style="margin-right: 0.5vw "></div>
            <div class ="product-nav-text-font" style="color: white" >
              |
            </div>
            <div style="margin-right: 0.5vw "></div>
            <a class ="product-nav-text-font" v-bind:style= "[config.isOption_2 ? {'color': config.activeColor} : {'color': config.disabledColor}]"
               @click="active_option_2">
              购买产品
            </a>
            <div style="margin-right: 0.5vw "></div>
            <div class ="product-nav-text-font" style="color: white">
              |
            </div>
            <div style="margin-right: 0.5vw "></div>
            <a class ="product-nav-text-font" v-bind:style= "[config.isOption_1 ? {'color': config.activeColor} : {'color': config.disabledColor}]"
               @click="active_option_1">
              所有产品
            </a>
          </ul>
        </div>
        <div v-if="config.isOption_1" style="position: relative; top: 5vh; left:2.5vw; width: 95%">
          <b-row>
            <!-- image top -->
            <b-col
                md="6"
                xl="4"
            >
              <b-card
                  :img-src="require('@/assets/images/TS/image2.jpeg')"
                  img-top
                  img-alt="card img"
                  title="儿童成长奶粉"
                  class="mb-3"
              >
                <b-card-text>
                  This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.

                </b-card-text>
              </b-card>
            </b-col>

            <b-col
                md="6"
                xl="4"
            >
              <b-card
                  :img-src="require('@/assets/images/TS/image1.png')"
                  img-top
                  img-alt="card img"
                  title="儿童成长奶粉"
                  class="mb-3"
              >
                <b-card-text >
                  This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.

                </b-card-text>
              </b-card>
            </b-col>
            <b-col
                md="6"
                xl="4"
            >
              <b-card
                  :img-src="require('@/assets/images/TS/image0.jpeg')"
                  img-top
                  img-alt="card img"
                  title="儿童成长奶粉"
                  class="mb-3"
              >
                <b-card-text>
                  This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <div v-if="config.isOption_2" style="position: relative; top: 5vh; left:2.5vw; width: 95%">
          option 2
        </div>
        <div v-if="config.isOption_3" style="position: relative; top: 5vh; left:2.5vw; width: 95%">
          option3
        </div>
        <div v-if="config.isOption_4" style="position: relative; top: 5vh">
          option 4
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <div class="container-fluid p-0">
      <div class="auth-bg-video-1 ">
        <video id="bgvid" poster="@/assets/images/pages/login/across-benlea-to-mount.jpg" playsinline="" autoplay="" muted=""
               loop="">
          <source src="@/assets/images/pages/login/auth-bg-2.mp4" type="video/mp4">
        </video>
        <div class="glassmorphism" >
        </div>
      </div>
      <div class="home-page-content-phone">
        <div style ="padding:25px"></div>
        <PhoneHomeHeader
            :config = "config"
        ></PhoneHomeHeader>
        <div v-if="!config.isNavMenuHidden">
          <ul class="subtitle-nav-text-phone"  >
            <div class ="product-nav-text-font-phone" v-bind:style= "[config.isOption_1 ? {'color': config.activeColor} : {'color': config.disabledColor}]"
                 @click="active_option_1">
              所有产品
            </div>
            <div class ="product-nav-text-font-phone"  >
              |
            </div>
            <div class ="product-nav-text-font-phone" v-bind:style= "[config.isOption_2 ? {'color': config.activeColor} : {'color': config.disabledColor}]"
                 @click="active_option_2">
              购买产品
            </div>
            <div class ="product-nav-text-font-phone">
              |
            </div>
            <div class ="product-nav-text-font-phone" v-bind:style= "[config.isOption_3 ? {'color': config.activeColor} : {'color': config.disabledColor}]"
                 @click="active_option_3">
              促销活动
            </div>
          </ul>
        </div>
        <div v-if="config.isOption_1" style="position: relative; top: 5vh; left:2.5vw; width: 95%">
          <b-row>
            <!-- image top -->
            <b-col
                md="6"
                xl="4"
            >
              <b-card
                  :img-src="require('@/assets/images/TS/image2.jpeg')"
                  img-top
                  img-alt="card img"
                  title="儿童成长奶粉"
                  class="mb-3"
              >
                <b-card-text>
                  This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.

                </b-card-text>
              </b-card>
            </b-col>

            <b-col
                md="6"
                xl="4"
            >
              <b-card
                  :img-src="require('@/assets/images/TS/image1.png')"
                  img-top
                  img-alt="card img"
                  title="儿童成长奶粉"
                  class="mb-3"
              >
                <b-card-text >
                  This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.

                </b-card-text>
                <b-card-text>
                  <small class="text-muted">Last updated 3 mins ago</small>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col
                md="6"
                xl="4"
            >
              <b-card
                  :img-src="require('@/assets/images/TS/image0.jpeg')"
                  img-top
                  img-alt="card img"
                  title="儿童成长奶粉"
                  class="mb-3"
              >
                <b-card-text>
                  This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <div v-if="config.isOption_2" style="position: relative; top: 5vh">
          option 2
        </div>
        <div v-if="config.isOption_3" style="position: relative; top: 5vh">
          option3
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeHeader from '@/views/home-page/components/HomeHeader.vue'
import PhoneHomeHeader from '@/views/home-page/components/PhoneHomeHeader.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import {
  BRow, BCol, BCard, BCardText, BCardTitle,
} from 'bootstrap-vue'
import 'swiper/css/swiper.css'


export default {
  name: 'Product',
  components: {
    PhoneHomeHeader,
    HomeHeader,
    Swiper,
    SwiperSlide,
    BCard,
    BCardText,
    BCardTitle,
    BRow,
    BCol,
  },
  methods: {
    _isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    active_option_1() {
      this.config.isOption_1 = true;
      this.config.isOption_2 = false;
      this.config.isOption_3 = false;
      this.config.isOption_4 = false;
    },
    active_option_2() {
      this.config.isOption_1 = false;
      this.config.isOption_2 = true;
      this.config.isOption_3 = false;
      this.config.isOption_4 = false;
    },
    active_option_3() {
      this.config.isOption_1 = false;
      this.config.isOption_2 = false;
      this.config.isOption_3 = true;
      this.config.isOption_4 = false;
    },
    active_option_4() {
      this.config.isOption_1 = false;
      this.config.isOption_2 = false;
      this.config.isOption_3 = true;
      this.config.isOption_4 = true;
    },

  },
  mounted: function() {
    if (this._isMobile()) {
      this.config.isMobile = true;
    }else {
      this.config.isMobile = false;
    }
  },

  data() {
    const config = {
      isMobile: false,
      isNavMenuHidden : false,
      isSearchDisabled : true,
      homeColor :  "#FFFFFF",
      aboutColor: "#FFFFFF",
      productColor: "#036EB8",
      mySpaceColor: "#FFFFFF",
      activeColor: "#036EB8",
      disabledColor: "#FFFFFF",
      isOption_1: true,
      isOption_2: false,
      isOption_3: false,
      isOption_4: false,
    }
    return {
      config,
      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/images/banner/milk-1.jpg') },
        { img: require('@/assets/images/banner/banner-4.jpg') },
        { img: require('@/assets/images/banner/banner-14.jpg') },
        { img: require('@/assets/images/banner/banner-3.jpg') },
        { img: require('@/assets/images/banner/banner-2.jpg') },
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@import '@core/scss/vue/pages/page-auth.scss';
.product-nav{
  background: rgba(3, 110, 184, 0.2);
  backdrop-filter: blur(88.5px);
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
}
.product-nav-text{
  display:flex;flex-direction: row;align-items: flex-start;
}
.product-nav-text-font{
  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5vh;
  line-height: 63px;

  color: #036DB8;
}
.product-nav-text-font-1{
  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5vh;
  line-height: 63px;

  color: #FFFFFF;
}
.subtitle-nav-text{
  display:flex; flex-direction: row-reverse;align-items: flex-start;
  position: relative;
  width: 100vw;
  height: 40px;

  top: 1vh;

  background: rgba(3, 110, 184, 0.2);
}
.home-page-content{
  position: absolute;
  top: 0;
  width: 100vw;
}

.product-nav-phone{
  background: rgba(3, 110, 184, 0.2);
  backdrop-filter: blur(88.5px);
  text-align: right;
  display: flex;
  flex-direction: row-reverse;

}
.product-nav-text-phone{
  display:flex;flex-direction: row;align-items: flex-start;
}
.product-nav-text-font-phone{
  position: relative;
  width: 61.6px;
  height: 18px;
  top: 8px;

  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;


}

.subtitle-nav-text-phone{
  display:flex;flex-direction: row;align-items: flex-start;
  position: relative;
  width: 100vw;
  height: 35px;
  left: 0px;
  top: 35px;

  background: rgba(3, 110, 184, 0.2);
}
.home-page-content-phone{
  position: absolute;
  top: 0;
}

</style>